import {PaymentRequestControllerProps} from '../../types/app.types';
import {BaseController} from '@wix/wixstores-client-storefront-sdk';
import {ControllerFlowAPI, ControllerParams} from '@wix/yoshi-flow-editor';
import {toMonitorError} from '../../domain/utils/errorMonitor.utils';

export class PaymentRequestController extends BaseController {
  protected translationPromise!: Promise<Record<string, string>>;

  constructor(controllerParams: ControllerParams) {
    super(controllerParams);
    this.setStores();
    this.loadLocaleDataSetTranslations(controllerParams);
  }

  private loadLocaleDataSetTranslations(controllerParams: ControllerParams) {
    this.translationPromise = this.fetchLocaleDataSetTranslations(controllerParams);
  }

  private async fetchLocaleDataSetTranslations(controllerParams: ControllerParams) {
    const {language} = controllerParams.flowAPI.translations.config;
    let translations: Record<string, string>;
    try {
      translations = await import(`@wix/locale-dataset-data/resources/translations/messages_${language}.json`);
    } catch (e) {
      (this.flowAPI as ControllerFlowAPI).errorMonitor.captureException(...toMonitorError(e));
      translations = (await import(
        `@wix/locale-dataset-data/resources/translations/messages_en.json`
      )) as unknown as Record<string, string>;
    }
    return {...controllerParams.flowAPI.translations.all, ...translations};
  }

  private async setLocaleDatasetTranslations() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.setProps({_translations: await this.translationPromise});
  }

  private setStores() {}

  private readonly updateComponent = () => {
    const props: PaymentRequestControllerProps = {
      isLoading: false,
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.setProps(props);
  };

  public readonly load = (): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.setProps({isLoading: true, fitToContentHeight: true});
    void this.loadData();
    return Promise.resolve();
  };
  public readonly loadData = (): void => {
    void this.setLocaleDatasetTranslations();
    if (this.siteStore.isSSR()) {
      return;
    }

    this.updateComponent();
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  public readonly init = async (): Promise<void> => {
    return this.load();
  };

  public getFreeTexts(): string[] {
    return [];
  }

  /* istanbul ignore next */
  public exports() {
    return {};
  }

  /* istanbul ignore next */

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onStyleUpdate(_styleParams: /* eslint-disable @typescript-eslint/ban-types */ {}) {}
}
